export const ORDER_ID = 'orderId';
export const FORMULA_ID = 'formulaId';
export const CODE = 'code';
export const OFFER_FORMULA_ID = 'offerFormulaId';
export const PARAM_FORMULA_ID = 'formula_id';
export const PAYMENT_REFERENCE = 'paymentReference';
export const SETTLEMENT_REFERENCE = 'settlementReference';
export const VITRINE_PATHNAME = 'aboshop-vitrine';
export const LAST_VALIDATED_STEP = 'lastValidatedStep';
export const SUBSCRIPTION_SCOPES = {
  PLUS_ARTICLES: 'PlusArticles',
  PUBLICATION: 'Publication',
};
export const UTM_KEY = '_mhtc_utm';
export const UTM_ARTICLE_ID = 'utm_artid';
export const ORIGIN_VITRINE = 'originVitrine';
export const GOTO = 'goto';
export const OFFER_DETAIL_PATH_NAME = 'offerDetailPathName';
export const STATUS_FAILED = 'failed';
export const DEFAULT = 'default';
export const RENEWAL = 'renewal';
export const SETTLEMENT = 'settlement';
export const ACQUISITION = 'acquisition';
export const VOUCHER = 'voucher';
export const WINBACK = 'winback';
export const CHECKOUT_FLOW = 'checkoutFlow';
export const CHOSEN_PAYMENT_INTERVAL = 'chosenPaymentInterval';
export const OFFER_ID = 'offerId';
export const SUBSCRIPTION_ID = 'subscriptionId';
export const MULTI_BRAND = 'multiBrand';
export const CXENSE_SEGMENTS = 'mhu_segments';
export const ABONNEE_SEGMENT = 'abonnee_segment';
export const APP_PLATFORM = 'appplatform';
export const NEWS_APP = 'newsapp';
export const PLATFORM = {
  IOS: 'ios',
  ANDROID: 'android',
  WEB: 'web',
};
export const MH_APPLICATION_NAME = 'mh_application_name';
export const IOS_NEWS_APP_APPLICATION_NAME = 'oneplatformios';
export const PERSONALISATION_CONSENT = 'personalisationconsent';
export const DEFAULT_SEGMENT = {
  name: 'default',
  variant: { name: 'default', path: 'porous-maxedout' },
};
export const RENEWAL_ID = 'renewalId';
export const CHECKOUT_FLOWS = {
  DEFAULT: 'default',
  RENEWAL: 'renewal',
  SETTLEMENT: 'settlement',
  ACQUISITION: 'acquisition',
  VOUCHER: 'voucher',
};
export const EMBEDDED_BROWSER = 'EmbeddedBrowser';
export const USER_LANGUAGE = 'userLanguage';
export const ORDER_HASH = 'orderHash';
export const VOUCHER_CODE = 'voucherCode';
export const CHECKOUT30_BACK_URL = 'checkout30BackUrl';
